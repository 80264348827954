import React from "react"
import { Link } from 'gatsby'
import Logo from "../../../static/images/logo/logo.svg"
import Seo from "../../components/seo"
import BookHeader from "../../components/bookPageHeader"
import BookSlider from "../../components/bookSlider"
import ReactBook from "../../../static/images/commonImg/reactbook.svg"
import ReactBookTS from "../../../static/images/commonImg/reactbook-ts.svg"
import ReactBook2 from "../../../static/images/commonImg/reactbook2.svg"
import ReactBook2TS from "../../../static/images/commonImg/reactbook2-ts.svg"
import NodeExpressBook from "../../../static/images/commonImg/nodebook-intro.svg"
import NodeExpressBook2 from "../../../static/images/commonImg/nodebook-advanced.svg"
import NextBook2a from "../../../static/images/commonImg/nextbook2-1.svg"
import NextBook2aTS from "../../../static/images/commonImg/nextbook2-1ts.svg"
import MernBook from "../../../static/images/commonImg/mern-book-1.svg"
import GatsbyBook from "../../../static/images/commonImg/gatsbybook.svg"
import NextBook from "../../../static/images/commonImg/nextbook.svg"
import JamstackBook from  "../../../static/images/commonImg/jamstackbook.svg"
import vuebook from "../../../static/images/commonImg/vuebook.svg"
import NuxtBook from "../../../static/images/commonImg/nuxtbook.svg"
import JSBook from "../../../static/images/commonImg/jsbook.svg"
//import ProxyBook from "../../../static/images/commonImg/proxyserver-book.png"
import RegisterNewsLetterBody from "../../components/registerNewsLetter"

const generalData = {
    title: "プロキシサーバーのつくり方",
    updated: "2022/08/23",
    urlToApp: "",
    urlToCode: "https://github.com/mod728/proxy-server-book",
    reviewLink1: "https://www.amazon.co.jp/review/create-review/?ie=UTF8&channel=glance-detail&asin=B0BBMQS28M",
}

const booksData = [
    {oldPrice: "", newPrice: "", newRelease: "", title: "はじめてつくるバックエンドサーバー基礎編（Node.js & Express）", img: NodeExpressBook, url: "https://www.amazon.co.jp/dp/B09HQZ1BW8", desciption: "近年のウェブ開発では、フロントエンドとバックエンドの連携がますます深まっています。<br/><br/>フロントエンド開発者が知っておくべきバックエンドまわりの仕組みを、Node.jsとExpressを使ってわかりやすく紹介します。"},
    {oldPrice: "", newPrice: "", newRelease: "", title: "MERNでつくるフルスタックアプリ（前編・後編）", img: MernBook , url: "https://www.amazon.co.jp/dp/B0BJN4H1NF", desciption: "Node.js、Express、React、MongoDBを使ってバックエンド、フロントエンド開発を行ない、アプリを作ります。<br/><br/>フルスタックアプリ開発の入門に最適な一冊。"},
    {oldPrice: "", newPrice: "", newRelease: "", title: "はじめてつくるVueアプリ", img: vuebook, url: "https://www.amazon.co.jp/dp/B0B46QNTNL", desciption: "「Reactの次はVueに挑戦したい」<br/><br/>という人向けのVue開発入門書です。<br/><br/>本書と同じ天気アプリを最新のバージョンのVueを使って開発します。"},
    {oldPrice: "", newPrice: "", newRelease: "", title: "はじめてつくるNext.jsサイト", img: NextBook, url: "https://www.amazon.co.jp/dp/B0C7746ZC4", desciption: "2時間でNext.jsの使い方がわかる入門書。<br/><br/>最新のNext.jsバージョン13と、新たに導入されたAppフォルダの使い方を解説している唯一の日本語書籍（2023年6月時点）"},
]

const booksDataForSlider = [
    {newRelease: "", title: "", img: ReactBook, url: "https://www.amazon.co.jp/dp/B08XWMVX76", desciption: ""},
    {newRelease: "", title: "", img: ReactBookTS, url: "https://www.amazon.co.jp/dp/B094Z1R281", desciption: ""},
    {newRelease: "", title: "", img: ReactBook2, url: "https://www.amazon.co.jp/dp/B09693ZJCV", desciption: ""},
    {newRelease: "", title: "", img: ReactBook2TS, url: "https://www.amazon.co.jp/dp/B09Q57Q9T1", desciption: ""},
    {newRelease: "", title: "", img: NodeExpressBook2, url: "https://www.amazon.co.jp/dp/B09X56PSCQ", desciption: ""},
    {newRelease: "", title: "", img: NextBook2a, url: "https://www.amazon.co.jp/dp/B09YWSHR58", desciption: ""},
    {newRelease: "", title: "", img: NextBook2aTS, url: "https://www.amazon.co.jp/dp/B0BD552RD6", desciption: ""},
    {newRelease: "", title: "", img: GatsbyBook, url: "https://www.amazon.co.jp/dp/B0BNQBDHFH", desciption: ""},
    {newRelease: "", title: "", img: JamstackBook, url: "https://www.amazon.co.jp/dp/B094Z1R281", desciption: ""},
    {newRelease: "", title: "", img: NuxtBook, url: "https://www.amazon.co.jp/dp/B0B5N8QJ2Y", desciption: ""},
    {newRelease: "", title: "", img: JSBook, url: "https://www.amazon.co.jp/dp/B0C9C9SVFG", desciption: ""},
]

const links = [
    [
        {name: "JSON Formatter", url: "https://chrome.google.com/webstore/detail/json-formatter/bcjindcccaagfpapjjmafapmmgkkhgoa/related"},
    ],
    [
        {name: "世界のコロナ感染データ", url: "https://react-book-corona-tracker-app.netlify.app/world"},
        {name: "世界のコロナ感染データ（API URL）", url: "https://monotein-books.vercel.app/api/corona-tracker/summary"},
        {name: "VS Code", url: "https://code.visualstudio.com/download"},
        {name: "Thunder Client", url: "https://marketplace.visualstudio.com/items?itemName=rangav.vscode-thunder-client"},
        {name: "エラー発生時の対処方法", url: "https://monotein.com/blog/how-to-find-and-fix-errors"},
    ],
    [
        {name: "日本のコロナ感染データ", url: "https://monotein-books.vercel.app/api/corona-tracker/country/japan"},
        {name: "ポーランドのコロナ感染データ", url: "https://monotein-books.vercel.app/api/corona-tracker/country/poland"},
        {name: "スペインのコロナ感染データ", url: "https://monotein-books.vercel.app/api/corona-tracker/country/spain"},
        {name: "vercel.jsonコード", url: "https://github.com/mod728/proxy-server-book/blob/master/vercel.json"},
        {name: "Vercel", url: "https://vercel.com"},
    ],
    [
        {name: "第3章完了時のコード", url: "https://github.com/mod728/proxy-server-book"},
        {name: "アマゾンレビューURL", url: generalData.reviewLink1},
    ]
]

     /*    {name: "", url: ""},  */

const ProxyServerBookLinks = () => (
    <div className="container2">
        <Seo title={generalData.title} noindex={true}/>
        <div><Link to="/blog"><img src={Logo} style={{width: "180px", margin: "auto", display: "block", paddingTop: "25px"}}/></Link></div>
        <div style={{textAlign: "center", marginTop: "16px"}}><a rel="noopener noreferrer" target="_blank" href="/blog">フロントエンド情報ブログ</a><span> || </span><a rel="noopener noreferrer" target="_blank" href="/business">著者について</a></div>
        <h3>『{generalData.title}』リンクページ</h3>
        <h4>更新日：<span>{generalData.updated}</span></h4>
        <BookHeader/>
        <ul>
            <h3 style={{backgroundColor: "#525252", display: "inline-block", color: "#fff", padding: "0 40px"}}>全般</h3>
            {generalData.urlToApp && <li>アプリ：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToApp}>{generalData.urlToApp}</a></li>}
            <li>ソースコードダウンロード：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode}>{generalData.urlToCode}</a></li>
            <li><span className="amazon-review">Amazonでレビューする</span>：<a rel="noopener noreferrer" target="_blank" href={generalData.reviewLink1}>{generalData.reviewLink1}</a></li>
        </ul>

        <div className="book-wrapper">
            <div className="gridContainer">
                {booksData.map((x, index) => 
                    <div className="imgTextContainer" key={index}>
                        <a rel="noopener noreferrer" target="_blank" href={x.url}>
                            <img src={x.img} alt="cover"/>
                        </a>
                        <div>
                            {x.newRelease && <p className="new-release">{x.newRelease}</p>}
                            <br/>
                            {x.oldPrice && <p className="new-release-price">リリース記念特価（期間限定）<span>{x.oldPrice}円</span> ➡ {x.newPrice}円</p>}
                            <h3>『{x.title}』</h3>
                            <a rel="noopener noreferrer" target="_blank" href={x.url}><p>Amazonで見る</p></a>
                            <p dangerouslySetInnerHTML={{ __html: x.desciption }}/>
                        </div>
                    </div>
                )}
            </div>
            <BookSlider booksDataForSlider={booksDataForSlider}/>
        </div>

        <ul>
            {links.map((x, index) => 
                <div key={index}>
                    <h3>{index === 0 ? "はじめに" : `第${index}章`}</h3>
                    {x.map((y, index) => 
                        <li key={index}>{y.name}： <a rel="noopener noreferrer" target="_blank" href={y.url}>{y.url}</a></li>
                    )}
                </div>
            )}
        </ul>
        <div style={{marginTop: "90px" }}></div>
        <RegisterNewsLetterBody 
                fromWhere="ProxyBook"
                backendUrl="https://us-central1-nov182021.cloudfunctions.net/server/news-letter"
                text={<p>React、Next.js、TypeScriptなど最新のウェブ開発のお役立ち情報を、ビギナー向けにかみ砕いて無料配信中。<br/>（*配信はいつでも停止できます）</p>}
        />
    </div>
)

export default ProxyServerBookLinks
